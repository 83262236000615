import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'

interface HeaderSubMenuProps {
  fields: Maybe<Component_Header_MenuHeader_Submenu>[]
}

export default function HeaderSubMenu({ fields }: HeaderSubMenuProps) {
  return (
    <div>
      <ul className="relative z-10 flex w-fit flex-col sm:px-3 sm:py-2 lg:bg-white lg:p-4 lg:shadow-xl">
        {fields.map((f, index: number) => (
          <li className="list-none border-b border-ubo-whiscash first-of-type:border-t" key={index}>
            <LosseLink to={f?.link?.url || '/'} target={f?.link?.target || '_self'} className="group block px-2 py-2 sm:px-4 sm:py-3">
              {f?.icon && <LossePlaatjie loading="eager" src={f?.icon} className="mx-auto h-8 w-8 sm:h-10 sm:w-10" />}
              <span className="block w-full font-september text-lg font-bold uppercase leading-5 text-white group-hover:underline lg:text-xl lg:text-ubo-taillow">
                {f?.link?.title}
              </span>
            </LosseLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
